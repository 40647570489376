<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="">
          <title></title>
          <b-row>
            <!-- credit card -->
            <b-col xl="4">
              <validation-provider #default="{ errors }" name="Name" rules="required">
                <b-form-group
                  label-for="cardNumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> صاحب البطاقة</label>
                  <v-select
                    v-model="Cardowner"
                    @open="Belongsto"
                    :options="optionTo"
                    :reduce="(val) => val.value"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="number"
                rules="min:5|required"
              >
                <b-form-group label-for="number">
                  <label> رقم البطاقة</label>
                  <StreamBarcodeReader
                    :camera="camera"
                    v-model="Cardnumber"
                  ></StreamBarcodeReader>
                  <b-form-input
                    v-model="Cardnumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                  />
                </b-form-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Reason" rules="required">
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> سبب الأستصدار</label>
                  <v-select
                    v-model="selectedReason"
                    :reduce="(val) => val.value"
                    :options="option"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider #default="{ errors }" name="center" rules="required">
                <b-form-group
                  label-for="center"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> صادرة عن </label>
                  <v-select
                    v-model="madeBy"
                    :reduce="(val) => val.value"
                    :options="optionFrom"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider #default="{ errors }" name="From" rules="required">
                <b-form-group
                  label-for="carddata"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> تاريخ الطباعة</label>
                  <flat-pickr
                    placeholder="حدد تاريخ الطباعة"
                    v-model="printdate"
                    class="form-control"
                    :config="{ maxDate: max }"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col xl="1"></b-col>
            <b-col
              cols="12"
              md="5"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  :src="require('@/assets/images/baraem.jpg')"
                  alt="Meeting Pic"
                  fluid
                />
              </div>
            </b-col>
          </b-row>
        </b-card-code>
        <b-row>
          <b-col md="12" xl="4"></b-col>
          <b-col class="border-Primary" md="12" xl="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="addFamilyCard"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">إنشاء بطاقة العائلة</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";
import VueBarcode from "vue-barcode";
import { FormWizard, TabContent } from "vue-form-wizard";

import { StreamBarcodeReader } from "vue-barcode-reader";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required, min } from "@validations";
import flatPickr from "vue-flatpickr-component";
// import FileInputBasic from './FileInputBasic.vue'
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import Ripple from "vue-ripple-directive";
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "familyCard",

  components: {
    BCardCode,
    BFormFile,
    FormWizard,
    TabContent,
    required,
    min,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,

    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BRow,
    flatPickr,
    StreamBarcodeReader,
    BCol,
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    return {
      camera: "off",
      optionTo: [],
      userId: "",
      max: maxDate,
      id: "",

      numberFamily: "",
      numberChild: "",
      Cardnumber: "",

      madeBy: "",
      Cardowner: "",
      printdate: "",
      selectedReason: "",
      option: [],
      optionFrom: [],
    };
  },

  directives: {
    Ripple,
  },

  created() {
    this.getDepartment();
    this.userId = localStorage.getItem("userId");
  },

  mounted() {},

  methods: {
    onDecode(result) {
      this.number = result;
    },

    addFamilyCard() {
      // //console.log(this.barcodeValue);
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.id = this.$store.state.id;
            let data = {
              number: this.Cardnumber,
              owner_id: this.Cardowner,
              issuance_reason_id: this.selectedReason,
              department_id: this.madeBy,
              print_date: this.printdate,
            };
            //console.log(data);

            this.$http.post("/api/v1/cards", data).then((res) => {
              this.$emit("finalStep");

              this.$swal({
                title: "",
                text: "تمت أضافة بطاقة العائلة بنجاح",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push({ name: "dashboard-ecommerce" });
            });
          }
        });
      });
    },

    Belongsto() {
      this.optionTo = [];
      this.id = this.$store.state.id;
      let url = `/api/v1/families/${this.id}/persons`;
      this.$http.get(url).then((response) => {
        let persons = response.data.data.data;
        persons.forEach((el) => {
          this.optionTo.push({ value: el.id, label: el.first_name });
        });
      });

      // }
    },
    getDepartment() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
           //console.log(res.data.data)
        let departments = res.data.data.departments;
     
        departments.forEach((el) => {
          this.optionFrom.push({ label: el.name, value: el.id });
        });
        let card_issuance_reasons = res.data.data.card_issuance_reasons;
        card_issuance_reasons.forEach((el) => {
          this.option.push({ label: el.name, value: el.id });
        });
      });
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: rgb(133, 130, 130);
  opacity: 1;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
.flo {
  margin-left: 300px;
}
</style>