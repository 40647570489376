export const location = {
  data() {
    return {
      selectedOldArea: '',
      selectedOldCity: '',

      selectedArea: '',
      selectedOldTown: '',
      selectCountryTown: '',
      selectCountryVillage: '',
      selectedCity: { value: 8, label: 'السويداء' },
      selectedVillages: '',
      selectedVillageT: '',

      optionOldvillages: [],
      optionOVillages: [],
      optionVillage: [],
      optionTown: [],
      optionOTown: [],
      optionOVillage: [],
      cities: [],
      optionOCity: [],
      optionCity: [],
      optionOgovernorate: [],
      optionVillages: [],
      optionCity: [],
      optiongovernorate: [],
      optionVgovernorate: [],
      optionTgovernorate: [],
      selectedOCounrtry: '',
      optionCountry: [],
    }
  },

  watch: {
    selectedCity: {
      handler() {
        this.optionCity = []

        const url = '/api/v1/governorates/8/cities'
        this.$http.get(url).then(response => {
          const Cities = response.data.data

          Cities.forEach(el => {
            this.optionCity.push({ label: el.name, value: el.id })
          })
        })
      },

      immediate: true,
    },
    selectCountryTown() {
      this.optionTgovernorate = []
      const url = `/api/v1/countries/${this.selectCountryTown.value}/governorates`
      this.$http.get(url).then(response => {
        const governates = response.data.data

        governates.forEach(el => {
          this.optionTgovernorate.push({ value: el.id, label: el.name })
        })
      })
    },
    selectCountryVillage() {
      this.optionVgovernorate = []
      const url = `/api/v1/countries/${this.selectCountryVillage.value}/governorates`
      this.$http.get(url).then(response => {
        const governates = response.data.data

        governates.forEach(el => {
          this.optionVgovernorate.push({ value: el.id, label: el.name })
        })
      })
    },
    selectedVillage() {
      this.optionTown = []
      const url = `/api/v1/towns/${familyFile.current_address.town_id}/neighborhoods`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionTown.push({ label: el.name, value: el.id })
        })
      })
    },
    selectedOldVillage() {
      this.optionOVillage = []
      const url = `/api/v1/towns/${this.selectedOldVillage}/neighborhoods`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionOTown.push({ label: el.name, value: el.id })
        })
      })
    },
    // selectedVillage: function() {
    //   this.optionVillage = [];
    //   let url = `/api/v1/cities/${this.selectedArea}/towns`;
    //   this.$http.get(url).then((response) => {
    //     let Village = response.data.data;

    //     Village.forEach((el) => {
    //       this.optionVillage.push({ label: el.name, value: el.id });
    //     });
    //   });
    // },
    selectedArea() {
      this.optionVillages = []
      const url = `/api/v1/cities/${this.selectedArea}/towns`
      this.$http.get(url).then(response => {
        const village = response.data.data

        village.forEach(el => {
          this.optionVillages.push({ label: el.name, value: el.id })
        })
      })
    },

    selectedOCounrtry() {
      this.optionOgovernorate = []
      this.selectedOldArea = ''
      const url = `/api/v1/countries/${this.selectedOCounrtry}/governorates`
      this.$http.get(url).then(response => {
        const governates = response.data.data

        governates.forEach(el => {
          this.optionOgovernorate.push({ value: el.id, label: el.name })
        })
      })
    },
    selectedOldCity: {
      handler() {
        this.optionOCity = []
        const url = `/api/v1/governorates/${this.selectedOldCity}/cities`
        this.$http.get(url).then(response => {
          const Cities = response.data.data

          Cities.forEach(el => {
            this.optionOCity.push({ label: el.name, value: el.id })
          })
        })
      },
      // immediate: true,
    },
    selectedOldArea() {
      this.optionOVillages = []
      const url = `/api/v1/cities/${this.selectedOldArea}/towns`
      this.$http.get(url).then(response => {
        const village = response.data.data

        village.forEach(el => {
          this.optionOVillages.push({ label: el.name, value: el.id })
        })
      })
    },
  },
}
