<template>
  <div>
    <b-modal
      ref="my-modal"
      centered
      cancelTitle="No"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
      <validation-observer ref="phonesimple">
        <b-card-code>
          <b-form v-for="(phone, i) in phones" :key="i">
            <validation-provider #default="{ errors }" name="phoneType" rules="required">
              <b-form-group
                label-for="phoneType"
                :state="errors.length > 0 ? false : null"
              >
                <label> وسبلة الاتصال</label>
                <v-select
                  v-model="phone.contact_type"
                  :options="contactType"
                  label="name"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="max:14|regex:^([0-9]+)$|required"
            >
              <b-form-group
                label-for="cardNumber"
                :state="errors.length > 0 ? false : null"
              >
                <label> الرقم</label>
                <b-form-input
                  v-model="phone.number"
                  id="cardNumber"
                  class="form-control"
                  :raw="false"
                  placeholder=" 999 999 099"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <b-form-group label-for="docNote">
              <label>الملاحظات</label>
              <b-form-textarea
                id="textarea-default"
                v-model="phone.notes"
                placeholder="الملاحظات"
                rows="1"
              />
            </b-form-group>
          </b-form>
        </b-card-code>
        <b-row>
          <b-col xl="4"></b-col>
          <b-col xl="4">
            <b-button variant="outline-primary" @click="addNumberr">
              <span class="align-middle"> إضافة الرقم </span>
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <b-table responsive="sm" :items="itemsNum" :fields="fields" />
      </validation-observer>
      <!-- </b-card-code> -->

      <template #modal-footer>
        <b-button variant="purple" @click="addNumbertoFamily">
          <span class="align-middle"> حفظ </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";
// import person from './person.vue';

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormFile,
  BFormGroup,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BModal,
  BFormInvalidFeedback,
  BTable,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import "flatpickr/dist/flatpickr.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, is_earlier, length } from "@validations";

import { BFormDatepicker, BFormInput, BFormTextarea, BButton } from "bootstrap-vue";
import { personDetails } from "@/@core/mixins/personDetails.js";
// import {
//   BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
// } from 'bootstrap-vue'
export default {
  name: "family-number",
  mixins: [personDetails],

  components: {
    BTable,
    length,
    is_earlier,
    BCardCode,
    BModal,
    required,
    BFormInvalidFeedback,
    BButton,
    max,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    flatPickr,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    ToastificationContent,
    BRow,

    BCol,
  },

  data() {
    return {
      fields: [
        { key: "name", label: "الوسيلة" },
        { key: "number", label: "الرقم" },
        { key: "notes", label: "الملاحظة" },
      ],

      items: [],
      contactType: [],
      allphones: [],
    };
  },

  created() {
    this.getAlldrowpDown();
  },

  methods: {
    resetModal() {
      this.phones = [{ number: "", notes: "", contact_type: "" }];
      requestAnimationFrame(() => {
        this.$refs.phonesimple.reset();
      });
    },
    show() {
      this.$refs["my-modal"].show();
    },
    getAlldrowpDown() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let contactType = res.data.data.contact_types;
        contactType.forEach((el) => {
          this.contactType.push({ name: el.name, id: el.id });
        });
      });
    },
    addNumbertoFamily() {
      let Familyphones = [];
      this.allphones.forEach((el) => {
        Familyphones.push({
          contact_type: el.contact_type.id,
          notes: el.notes,
          number: el.number,
        });
      });
      this.$emit("getFamilyPhone", Familyphones);

      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
  width: 200px;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.btn-delete {
  color: white;
  width: 200px;
  background-color: #ea5455 !important;
}

.but {
  top: 25px;
}
</style>
