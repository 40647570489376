import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // .post("/api/all_addbinifit", { family_id: this.id })
    async addFamily(ctx, payload) {
       return new Promise((resolve, reject) => {
 
     axios
          .post('/api/v1/families',payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    
    },
    
    fetchFamilieSer(ctx, { id }) {
      return new Promise((resolve, reject) => {
  
        let url = `/api/v1/families/${id}/get_services_for_whole_family`;
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })},
    fetchFamiliesBenef(ctx, { id }) {
      return new Promise((resolve, reject) => {
       
        let url = `/api/v1/families/${id}/persons`;
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    
    getRecognitionBy(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/recognized_by_methods')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    getResidenceTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/residence_types')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    getFamilyStatus(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/family_statuses')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    fetchAddition(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/information_manager/pending_create_actions')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    sendResponseaAdd(ctx,approvestate) {
      return new Promise((resolve, reject) => {
        let data={
          approval_status:approvestate.approvestate
        }
      
        let url=`/api/v1/information_manager/createActions/${approvestate.action_id}`
        axios
          .put(url,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    sendResponse(ctx,approvestate) {
      return new Promise((resolve, reject) => {
        let data={
          approval_status:approvestate.approvestate
        }
      
        let url=`/api/v1/information_manager/modifications/${approvestate.action_id}`
        axios
          .put(url,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    
  },
}
