<template>
  <div>
    <b-col cols="12">
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        ref="formWiz"
        :hide-buttons="true"
        class="mb-3"
      >
        <tab-content
          title="إنشاء الملف"
          :before-change="validationForm"
          icon="feather icon-file-text"
        >
          <validation-observer ref="createFile">
            <add-family />
          </validation-observer>
        </tab-content>

        <tab-content
          title="الأفراد"
          :before-change="validationFormInfo"
          icon="feather icon-user"
        >
          <validation-observer ref="simple">
            <person
              v-for="(person, index) in persons"
              :key="index"
              @anotherPerson="onClick"
              @getFamilyId="getFamilyId"
            />
          </validation-observer>
          <div>
            <person-list :id="familyId" ref="childComponentRef" />
          </div>
        </tab-content>
        <tab-content
          title="الخدمات"
          :before-change="validationFormService"
          icon="feather icon-file-text"
        >
          <validation-observer ref="formService">
            <service @getFamilySer="getFamilySer" />
            <service-list :id="familyId" ref="serviceComponentRef" />
          </validation-observer>
        </tab-content>

        <tab-content title="بطاقة العائلة" icon="feather icon-file-text">
          <familyCard />
        </tab-content>
      </form-wizard>
    </b-col>

    <div class="d-flex">
      <div>
        <b-button
          @click="lastTab"
          v-if="startIndex > 0"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="shadow"
        >
          <feather-icon icon="ChevronRightIcon" class="mr-50" />
          <span class="align-middle"> الصفحة السابقة</span>
        </b-button>
      </div>
      <div class="ml-auto">
        <b-button
          @click="nextTab"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="shadow"
          v-if="!isLastStep"
        >
          <span>الصفحة التالية </span>
          <feather-icon icon="ChevronLeftIcon" />
        </b-button>
      </div>
    </div>

    <!-- </b-col> -->
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BRow, BCol, BButton } from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationObserver } from "vee-validate";
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import personList from "./person-list.vue";
import serviceList from "./service-list.vue";
import addFamily from "./add-family.vue";
import service from "./services.vue";
import familyCard from "./family-card.vue";
import person from "./person.vue";
export default {
  components: {
    BRow,
    FormWizard,
    serviceList,
    personList,
    BCol,
    TabContent,
    BButton,
    addFamily,
    familyCard,
    service,
    person,
    ValidationObserver,
  
  },

  data() {
    return {
      persons: [person],
      firstTab: true,
      isLastStep:false,

      familyId: null,
      oneperson:false,
      oneService:false,
      startIndex:0
    };
  },

  directives: {
    Ripple,
  },

  methods: {
    onClick() {
      this.persons.push(person);
    },

    lastTab() {
      this.$refs.formWiz.prevTab();
      this.startIndex--
    },
    getFamilySer(id) {
this.oneService=true
      this.familyId = id;
      this.$refs.serviceComponentRef.refetchData();
    },
    getFamilyId(id) {
      this.oneperson=true
      this.familyId = id;
      this.$refs.childComponentRef.refetchData();
    },
    nextTab() {
      this.$refs.formWiz.nextTab();
      this.startIndex++
    },
    validationFormInfo() {
    
  
      return new Promise((resolve, reject) => {
    
        if (this.oneperson) {
  
      this.$swal({
        title: "هل تريد إضافة أفراد آخرين",
        
        icon: 'warning',
        showCancelButton: true,
          allowEscapeKey: false,
   
      allowOutsideClick: false,
        confirmButtonText: 'موافق',
             cancelButtonText: 'لا',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
         
            reject();
        }
        else{
        
          resolve(true)
        }
      })}
      else {
           this.$swal({
 
        text: "يجب إضافة فرد واحد على الأقل",
        icon: 'warning',
     
        confirmButtonText: 'موافق',
           allowEscapeKey: false,
   
      allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
         
            reject();
        }
        else{
          resolve(true)
        }
      })
            reject();
          }
      
      })
    },
    
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.createFile.validate().then((success) => {
          if (success) {
            resolve(true);
            this.firstTab = false;
          } 
          else {
            reject();
          }
        });
      });
    },
    validationFormService() {
     return new Promise((resolve, reject) => {
    
        if (this.oneService) {
  
      this.$swal({
        title: "هل تريد إضافة خدمات آخرى",
        
        icon: 'warning',
        showCancelButton: true,
          allowEscapeKey: false,
   
      allowOutsideClick: false,
        confirmButtonText: 'موافق',
             cancelButtonText: 'لا',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
         
            reject();
            
        }
        else{
          this.isLastStep=true
        
          resolve(true)
        }
      })}
      else {
           this.$swal({
 
        text: "يجب إضافة خدمة واحدة على الأقل",
        icon: 'warning',
     
        confirmButtonText: 'موافق',
           allowEscapeKey: false,
   
      allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
         
            reject();
        }
        else{
    
          resolve(true)
        }
      })
            reject();
          }
      
      })
    },
  },
};
</script>

<style scoped>
.next {
  margin-left: 800px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>