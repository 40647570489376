<template>
  <div>
    <validation-observer ref="simple">
      <b-card-code>
        <b-form>
          <b-row>
            <b-col md="" xl="2">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="Inputperson"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>الشخص المدخل</label>
                  <v-select
                    v-model="selectedRel"
                    :reduce="(val) => val.value"
                    @input="getGender(selectedRel)"
                    :options="optionRelationship"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <label>الأسم</label>

                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="الاسم"
                    v-model="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="fname">
                <validation-provider #default="{ errors }" name="fname" rules="required">
                  <label>أسم الأب</label>
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="helperInput"
                    v-model="FName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="lname">
                <validation-provider #default="{ errors }" name="lname" rules="required">
                  <label>النسبة </label>

                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    v-model="FamilyName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <div > -->
            <b-col md="12" xl="2" v-if="this.selectedRel.id == 5">
              <b-form-group label-for="mname">
                <validation-provider #default="{ errors }" name="mname" rules="required">
                  <label>أسم الأم</label>

                  <v-select v-model="Mname" :options="optionMom" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2" v-else>
              <b-form-group label-for="mname">
                <validation-provider #default="{ errors }" name="mname" rules="required">
                  <label>أسم الأم</label>

                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    v-model="Mname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="bplace">
                <validation-provider #default="{ errors }" name="field" rules="required">
                  <label> مكان الولادة</label>
                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    v-model="PlaceBir"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" xl="2">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="BirthDate"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> تاريخ الميلاد</label>

                  <flat-pickr
                    v-model="dateOfBirth"
                    class="form-control"
                    placeholder="حدد تاريخ الميلاد"
                    :config="{ maxDate: max }"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider #default="{ errors }" name="select" rules="required">
                <b-form-group label-for="time" :state="errors.length > 0 ? false : null">
                  <label> الجنس</label>
                  <v-select
                    v-model="selectgender"
                    :reduce="(val) => val.value"
                    :options="optiongender"
                    @input="getWorkType(selectgender)"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- Numeral Formatting -->

            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="social status"
                rules="required"
              >
                <b-form-group label-for="time" :state="errors.length > 0 ? false : null">
                  <label> الحالة الأجتماعية</label>
                  <v-select
                    v-model="selectstatus"
                    :reduce="(val) => val.value"
                    :options="MartialState"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="studylevel"
                rules="required"
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> التحصيل العلمي</label>
                  <v-select
                    v-model="selectedStudy"
                    :reduce="(val) => val.value"
                    :options="optionStudy"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="studystate"
                rules="required"
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> حالة التعليم</label>
                  <v-select
                    v-model="selectedStudyState"
                    :reduce="(val) => val.value"
                    :options="EducationalState"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="person state"
                rules="required"
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> حالة الفرد</label>
                  <v-select
                    v-model="selectedS"
                    :reduce="(val) => val.value"
                    :options="RegistrationStatuses"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="3">
              <validation-provider #default="{ errors }" name="NewWork" rules="required">
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> العمل الحالي</label>
                  <v-select
                    v-model="selectedWork"
                    :reduce="(val) => val.value"
                    :options="optionJobs"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="nowNote"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <validation-provider #default="{ errors }" name="LastWork" rules="required">
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> العمل السابق</label>
                  <v-select
                    v-model="selectedLastWork"
                    :reduce="(val) => val.value"
                    :options="optionJobs"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="lastNote"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="3">
              <validation-provider
                #default="{ errors }"
                name="Nationality"
                rules="required"
              >
                <b-form-group label-for="time" :state="errors.length > 0 ? false : null">
                  <label> الجنسية</label>
                  <v-select
                    v-model="selectedNa"
                    :reduce="(val) => val.value"
                    :options="optionNationality"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="4" xl="4">
              <b-form-group label-for="number">
                <!-- <validation-provider #default="{ errors }" name="note" rules="required"> -->
                <label> ملاحظات عامة</label>
                <b-form-textarea
                  v-model="singleNotes"
                  id="textarea-default"
                  placeholder="الملاحظات"
                  rows="1"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <br />
        <br />
        <b-row>
          <b-col md="6" xl="3">
            <b-button variant="purple" @click="addNumPerson">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">إضافة وسيلة اتصال </span>
            </b-button>
            <br />
            <br />
            <h6 class="text-success" v-if="saveNumbers">
              <feather-icon class="text-success" icon="CheckIcon" />
            </h6>
          </b-col>

          <b-modal
            ref="my-modal"
            centered
            ok-title="حفظ"
            cancel-variant="outline-secondary"
            title-tag="div"
          >
            <!-- <b-card-code  title="معلومات الاتصال الخاصة بالعائلة" >/ -->
            <validation-observer ref="phonesimple">
              <b-card-code>
                <b-form v-for="(phone, i) in phones" :key="i">

                  <validation-provider
                    #default="{ errors }"
                    name="phoneType"
                    rules="required"
                  >
                  <b-form-group label-for="phoneType"  :state="errors.length > 0 ? false : null">
                    <label> وسبلة الاتصال</label>
                    <v-select
                      v-model="phone.contact_type"
                      :options="contactType"
                      label="name"
                    />
                  </b-form-group>
      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="titlekn"
                    rules="max:14|regex:^([0-9]+)$|required"
                  >
                    <b-form-group
                      label-for="cardNumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الرقم</label>
                      <b-form-input
                        v-model="phone.number"
                        id="cardNumber"
                        class="form-control"
                        :raw="false"
                        placeholder=" 999 999 099"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <b-form-group label-for="docNote">
                    <label>الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="phone.notes"
                      placeholder="الملاحظات"
                      rows="1"
                    />
                  </b-form-group>
                  <hr />
                </b-form>
              </b-card-code>
              <b-row>
                <b-col xl="4"></b-col>
                <b-col xl="4">
                  <b-button variant="outline-primary" @click="addNumberr">
                    <span class="align-middle"> إضافة الرقم </span>
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-table responsive="sm" :items="itemsNum" :fields="fieldsNum" />
            </validation-observer>
            <!-- </b-card-code> -->

            <template #modal-footer>
              <b-button variant="purple" @click="addNumbertoPerson">
                <span class="align-middle"> حفظ </span>
              </b-button>
            </template>
          </b-modal>

          <b-col md="6" xl="3">
            <b-button variant="purple" @click="addDocPerson">
              <feather-icon icon="PlusIcon" class="mr-50" />

              <span class="align-middle">إضافة وثيقة </span>
            </b-button>
            <br />
            <br />
            <h6 class="text-success" v-if="saveDocuments">
              <feather-icon class="text-success" icon="CheckIcon" />
            </h6>
          </b-col>

          <b-modal
            ref="modal-documents"
            centered
            cancel-variant="outline-secondary"
            title-tag="div"
          >
            <validation-observer ref="documentsimple">
              <b-card-code>
                <b-form v-for="(document, index) in documents" :key="index">

                  <validation-provider #default="{ errors }" rules="required" >
                    <b-form-group

                      label-for="cardNumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نوع الوثيقة</label>
                      <v-select
                        v-model="document.document_type"
                        :options="optionDocuments"
                        label="name"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
<b-col    v-if="document.document_type">
                  <validation-provider
                    #default="{ errors }"
                    rules="regex:^([0-9]+)$"
               v-if="document.document_type.id !==5 && document.document_type.id !==6"
                  >
                    <b-form-group
                      label-for="numberdocument"
                      :state="errors.length > 0 ? false : null"

                    >
                      <label> رقم الوثيقة</label>

                      <b-form-input v-model="document.number" placeholder="رقم الوثيقة" />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <validation-provider #default="{ errors }" name="daisp" rules="required"          v-if="document.document_type.id !==5 && document.document_type.id !==6">
                    <b-form-group
                      label-for="date"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> تاريخ الأصدار</label>
                      <flat-pickr
                        v-model="document.release_date"
                        class="form-control"
                        placeholder="حدد تاريخ الأصدار"
                        locale="en"
                          :config="{      static:'true' }"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <validation-provider #default="{ errors }" name="dateDisp" rules=""          v-if="document.document_type.id !==5 && document.document_type.id !==6 && document.document_type.id !==1 && document.document_type.id !==2" >
                    <b-form-group
                      label-for="date"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> تاريخ الأنتهاء</label>
                      <flat-pickr
                        v-model="document.expiration_date"
                        class="form-control"
                        placeholder="حدد تاريخ الأنتهاء"
                           :config="{      static:'true' }"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <b-form-group label-for="docNote">
                    <label>الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="document.notes"
                      placeholder="الملاحظات"
                      rows="1"
                    />
                  </b-form-group>
                  </b-col>
                  <hr />
                </b-form>
              </b-card-code>
              <b-row>
                <b-col xl="4"></b-col>
                <b-col xl="4">
                  <b-button variant="outline-primary" @click="adddocument">
                    <span class="align-middle"> إضافة الوثيقة </span>
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-table responsive="sm" :items="itemsDoc" :fields="fieldsDoc" />
            </validation-observer>
            <template #modal-footer>
              <b-button variant="purple" @click="addDocumenttoPer">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> حفظ </span>
              </b-button>
            </template>
          </b-modal>

          <b-col md="6" xl="3">
            <b-button variant="purple" @click="addWekPerson">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">إضافة نقطة ضعف للفرد </span>
            </b-button>
            <div></div>

            <feather-icon class="text-success" icon="CheckIcon" v-if="saveWeakness" />
          </b-col>

          <b-modal
            ref="modal-weakness"
            centered
            cancel-variant="outline-secondary"
            title-tag="div"
          >
            <validation-observer ref="personweakness">
              <b-card-code>
                <b-form>
                  <b-row v-for="(weak, index) in weaknesses" :key="index">
                    <!-- block -->
                    <b-col md="4" xl="6">
                      <validation-provider #default="{ errors }" 
                      :rules="{ subOptionRequired: { optionsWeak: optionsWeak } }"
                      >
                        <b-form-group label-for="weakness">
                          <label> نقاط الضعف</label>
                          <treeselect
                            :multiple="false"
                            :options="optionsWeak"
                            v-model="weak.weakness_id"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <!-- delimiters -->
                    <b-col md="8" xl="6">
                      <b-form-group label-for="delimiter">
                        <label> الملاحظات</label>
                        <b-form-textarea
                          id="textarea-default"
                          placeholder="الملاحظات"
                          v-model="weak.notes"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <br />
                  <br />
                  <b-row> </b-row>
                </b-form>
              </b-card-code>
              <b-row>
                <b-col md="6" xl="4"></b-col>
                <b-col md="6" xl="4">
                  <b-button variant="outline-primary" @click="addWeak">
                    <span class="align-middle"> إضافة النقطة </span>
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-table responsive="sm" :items="itemsWek" :fields="fieldsWek" />
            </validation-observer>
            <template #modal-footer>
              <b-button variant="purple" @click="addWeaknesstoPer">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> حفظ </span>
              </b-button>
            </template>
          </b-modal>
        </b-row>
      </b-card-code>
      <br />

      <b-row>
        <b-col md="12" xl="4"></b-col>
        <b-col
          class="border-Primary d-flex justify-content-between flex-column mt-1 mt-sm-0"
          md="12"
          xl="3"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="shadow"
            @click="addbeneficiary"
          >
            <feather-icon icon="UserPlusIcon" class="mr-50" />
            <span class="align-middle">إضافة الفرد</span>
          </b-button>
        </b-col>
      </b-row>
      <br />

      <br />
    </validation-observer>

    <br />
  </div>
</template>

<script>
import vSelect from "vue-select";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormFile,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BModal,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

import "flatpickr/dist/flatpickr.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import familyNumber from "./add-family-number.vue";
import familyDocument from "./add-family-documents.vue";
import { required, max, is_earlier } from "@validations";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BFormGroup,
} from "bootstrap-vue";
import { personDetails } from "@/@core/mixins/personDetails.js";
import Ripple from "vue-ripple-directive";
export default {
  name: "person",
  mixins: [personDetails],

  components: {
    Treeselect,
    BTable,
    familyNumber,
    familyDocument,

    BModal,
    is_earlier,
    BCardCode,
    required,
    BFormInvalidFeedback,
    BButton,
    max,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInput,

    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    flatPickr,
    BFormTextarea,
    vSelect,
    BFormCheckbox,

    BRow,

    BCol,
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());

    return {
      jobs: [],
test:false,
      RegistrationStatuses: [],
      notesFamily: "",
      optionJobs: [],
      saveWeakness: false,
      saveDocuments: false,
      saveNumbers: false,

      selectstatus: "",
      dateOfBirth: "",
      singleNotes: "",

      name: "",
      lName: "",
      father: "",
      FamilyName: "",
      mother: "",
      Mname: "",
      FName: "",
      lastName: "",
      PlaceBir: "",
      numberFamily: 0,
      numberChild: 0,
      dateReg: "",
      dateDisp: "",
      selectedS: "",
      selectedMom: "",
      optionMom: [],
      selectedWeak: [],
      selectedStudyState: "",
      selectedLastWork: "",
      nowNote: "",
      note: "",
      lastNote: "",
      lastWork: "",
      AddedPerson: false,
      selectedStudy: "",
      selectedWork: "",
      selectedRel: "",
      selectedNa: "",
      selectgender: "",
      selectedFamily: "",
      selectedknow: "",
      termW: false,
      number: "",
      numberphone: "",
      selectedCall: "",
      value: null,

      dateRelease: "",
      dateEnd: "",
      del: false,
      rules: {
        required,
        isBeyond: {
          compare: this.dateRelease,
          dateType: "تاريخ الانتهاء",
        },
      },
      number: "",
      value: "",
      alldocuments: [],
      Personphones: [],
      Personweakness: [],
        PersonDocuments: [],
      optionTo: [],
      optionDocuments: [],
      optionsWeak: [],
      contactType: [],


      optionWeakness: [],
      EducationalState: [],

      optiongender: [],
      // optionState: [ "زائر",  "مسجل" ],
      optionNationality: [],
      optionStudy: [],
      optionRelationship: [],
      MartialState: [],

      max: maxDate,
      msg: {
        required: "هذا الحقل مطلوب",
      },

      personid: "",
      id: "",
      weaknotes: "",
    };
  },

  directives: {
    Ripple,
  },

  created() {
    this.getAlldrowpDown();
    this.getWeak();
    this.rules = {
      is_earlier: {
        compare: this.documents.expireDate,
        dateType: "تاريخ التسجيل",
      },
      // required,
    };
  },

  methods: {
    getGender(id){
        //console.log(this.optionRelationship)
if(id==1 || id==2 ||id==3 || id==4){
    this.selectgender=1
    this.getWorkType(1)

}
else if(id==6 || id==7 ){
    this.selectgender=2
    this.getWorkType(2)
}
    },
    addbeneficiary() {


      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              this.id = this.$store.state.id;
              let data;

              // if (!this.term) {
              data = {
                first_name: this.name,
                last_name: this.FamilyName,
                father_name: this.FName,
                mother_name: this.Mname,
                birth_date: this.dateOfBirth,
                birth_place: this.PlaceBir,
                nationality_id: this.selectedNa,
                martial_status_id: this.selectstatus,
                family_relationship_id: this.selectedRel,
                educational_attainment_id: this.selectedStudy,
                educational_status: this.selectedStudyState,
                current_work_id: this.selectedWork,
                current_work_note: this.nowNote,
                last_work_note: this.lastNote,
                last_work_id: this.selectedLastWork,
                registration_status: this.selectedS,
                notes: this.singleNotes,
                gender: this.selectgender,
                contact_details: this.Personphones,
                person_documents: this.PersonDocuments,
                weaknesses: this.Personweakness,

                family_id: this.id,
              };
              function replaceUndefinedOrNull(key, value) {
                if (value === null || value === "" || value === undefined) {
                  return undefined;
                }

                return value;
              }

              let datajson = JSON.stringify(data, replaceUndefinedOrNull);
              let dataparse = JSON.parse(datajson);
              function isEmptyObject(obj) {
                return JSON.stringify(obj) === "[{}]";
              }
              for (let key of Object.keys(dataparse)) {
                if (isEmptyObject(dataparse[key])) {
                  delete dataparse[key];
                }
              }
              // let k=Object.entries(exampleObject).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})

              this.$http.post("/api/v1/persons", dataparse).then((res) => {
                this.$emit("getFamilyId", this.id);
                this.personid = res.data.data.id;

                this.AddedPerson = true;
                this.itemsDoc = [];
                this.itemsWek = [];
                this.itemsNum = [];
                this.Personweakness =[];
                this.Personphones =[];
               this.PersonDocuments =[];

                (this.saveNumbers = false),
                  (this.saveDocuments = false),
                  (this.saveWeakness = false);

                let numberFamily = res.data.data.family_members_count;

                let numberchild = res.data.data.family_children_count;

                this.$swal({
                  title: "",

                  text: `تم اضافة  ${this.name}
                أصبح
        عدد الأفراد ${numberFamily}

        منهم
        عدد الأطفال ${numberchild}
         `,
                  icon: "success",
                  confirmButtonText: "موافق",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                (this.documents = [
                  {
                    release_date: "",
                    expiration_date: "",
                    number: null,
                    document_type_id: null,
                    notes: "",
                  },
                ]),
                  (this.phones = [
                    {
                      number: "",
                      notes: "",
                      contact_type: "",
                    },
                  ]),
                  (this.name = ""),
                  (this.FamilyName = ""),
                  (this.FName = ""),
                  (this.Mname = ""),
                  (this.dateOfBirth = ""),
                  (this.PlaceBir = ""),
                  (this.selectedNa = ""),
                  (this.selectstatus = ""),
                  (this.selectedRel = ""),
                  (this.selectedStudy = ""),
                  (this.selectedStudyState = ""),
                  (this.selectedWork = ""),
                  (this.nowNote = ""),
                  (this.lastNote = ""),
                  (this.selectedLastWork = ""),
                  (this.selectedS = ""),
                  (this.singleNotes = ""),
                  (this.selectgender = "");
                this.weaknesses = [
                  {
                    weakness_id: "",
                    notes: "",
                  },
                ];
                requestAnimationFrame(() => {
                  this.$refs.simple.reset();
                });
              });
            }
          })
          .catch((error) => {

            this.$swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      });
      //  this.$refs['simple'].reset()
    },


    getWorkType(id) {
      this.optionJobs = [];
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let work_types = res.data.data.work_types;
        work_types.forEach((el) => {
          if (el.gender == id || el.gender == 0) {
            this.optionJobs.push({ label: el.name, value: el.id });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
  width: 200px;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.btn-delete {
  color: white;
  width: 200px;
  background-color: #ea5455 !important;
}
.label {
  font-size: 15px;
}

.but {
  top: 25px;
}
</style>
