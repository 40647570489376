<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
      </div>

      <b-table
        ref="refFamiliesListTable"
        class="position-relative"
        :items="fetchFamiliesSer"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
      </b-table>
    </b-card>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { watch } from "@vue/composition-api";

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";

import { ref, onUnmounted } from "@vue/composition-api";


import familyStoreModule from "@/views/apps/families/familyStoreModule";
export default {
  setup(props) {
    const FAMILY_APP_STORE_MODULE_NAME = "app-family";

    // Register module
    if (!store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
      store.registerModule(FAMILY_APP_STORE_MODULE_NAME, familyStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
        store.unregisterModule(FAMILY_APP_STORE_MODULE_NAME);
    });

    const refFamiliesListTable = ref(null);
    const fetchFamiliesSer = (ctx, callback) => {
     
      store
        .dispatch("app-family/fetchFamilieSer", { id: props.id })

        .then((response) => {


          const { data, total } = response.data;

          callback(data);
        });
    };
    watch(
      () => props.id,
      () => {
        refetchData();
      }
    );
    const refetchData = () => {
      refFamiliesListTable.value.refresh();
    };
    const tableColumns = [
      { key: "service_for", label: "الاسم", sortable: true },
      {
        key: "name",
        label: "   الخدمة",

        sortable: true,
      },
    ];

    return {
      // Sidebar

      fetchFamiliesSer,

      tableColumns,

      refFamiliesListTable,
      refetchData,
    };
  },

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BCardCode,
  },

  props: {
    id: {
      type: Number,
    },
  },
};
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>